<template>
  <v-select
    v-model="pickerValue"
    :items="perks"
    item-value="id"
    item-text="name"
    v-bind="$attrs"
    multiple
    small-chips
    persistent-hint
  />
</template>
<script>
import axios from 'axios';

export default {
  name: 'PerkPicker',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pickerValue: '',
      perks: [],
    };
  },
  watch: {
    pickerValue() {
      this.$emit('input', this.pickerValue);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pickerValue = this.value;
      this.loadPerks();
    });
  },
  methods: {
    loadPerks() {
      axios.get(this.$store.state.endpoints.offers.admin.perks)
        .then(response => {
          this.perks = response.data.results;
        })
        .catch(e => {
          console.log(e);
        });
    },
  },
};
</script>
