<template>
  <v-autocomplete
    v-model="pickerValue"
    :disabled="areas.length < 1"
    :loading="loading"
    :items="areas"
    item-value="uuid"
    item-text="name"
    v-bind="$attrs"
    clearable
  />
</template>

<script>
import axios from 'axios';

export default {
  name: 'AreaPicker',
  props: {
    value: {
      type: String,
      default: '',
    },
    deprecated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pickerValue: '',
      areas: [],
      loading: false,
    };
  },
  watch: {
    pickerValue() {
      this.$emit('input', this.pickerValue);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pickerValue = this.value;
      this.loadAreas();
    });
  },
  methods: {
    loadAreas() {
      this.loading = true;
      const qpar = this.deprecated ? '?include_deprecated=true' : '';
      const endpoint = `${this.$store.state.endpoints.offers.public.areas}${qpar}`;
      axios.get(endpoint)
        .then(response => {
          this.areas = response.data;
          this.loading = false;
        });
    },
  },
};
</script>
