<template>
  <v-form>
    <v-container>
      <v-layout
        row
        wrap
        mb-5
      >
        <v-flex xs12>
          <v-btn
            color="warning"
            @click="$router.push({name: 'admin:offers'})"
          >
            <v-icon left>
              undo
            </v-icon>
            Volver
          </v-btn>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="offer.position"
            label="Cargo *"
            :error-messages="positionErrors"
            @blur="updateTitle"
          />
        </v-flex>

        <v-flex
          xs12
          sm6
          md6
        >
          <v-text-field
            v-model="offer.company"
            label="Empresa *"
            :error-messages="companyErrors"
          />
        </v-flex>

        <v-flex
          xs12
          sm6
          md6
        >
          <AreaPicker
            v-model="offer.area"
            label="Área *"
            :error-messages="areaErrors"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <CountryPicker
            v-model="offer.country"
            label="País *"
            :error-messages="countryErrors"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <RegionPicker
            :key="offer.country"
            v-model="offer.region"
            :country="offer.country"
            :disabled="!offer.country"
            label="Seleccione región"
            :error-messages="regionErrors"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <CityPicker
            ref="citypicker"
            :key="offer.region"
            v-model="offer.city"
            :region="offer.region"
            :disabled="!offer.region"
            label="Seleccione ciudad o comuna"
            :error-messages="cityErrors"
            @input="updateTitle"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <v-select
            v-model="offer.contract_type"
            label="Tipo de contrato *"
            :items="contracts"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <v-text-field
            v-model="offer.vacancies"
            label="Vacantes *"
            :error-messages="vacanciesErrors"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <StudiesPicker
            v-model="offer.studies"
            prepend-icon="school"
            :country="offer.country"
            label="Nivel de Estudios"
            :error-messages="studiesErrors"
            @input="$v.offer.studies.$touch()"
            @blur="$v.offer.studies.$touch()"
          />
        </v-flex>

        <v-flex
          xs12
          sm4
        >
          <v-select
            v-model="offer.position_tier"
            label="Nivel del Cargo *"
            :items="position_tiers"
          />
        </v-flex>
        <v-flex
          xs12
          sm8
        >
          <PerkPicker
            v-model="offer.perks"
            label="Beneficios de la empresa"
          />
        </v-flex>
        <v-flex
          xs12
        >
          <v-label>Renta Ofrecida</v-label>
        </v-flex>
        <v-flex
          xs6
          sm4
        >
          <v-text-field
            v-model.number="offer.offer_wage_min"
            type="number"
            label="Desde *"
            :error-messages="wageErrors"
          />
        </v-flex>
        <v-flex
          xs6
          sm4
        >
          <v-text-field
            v-model.number="offer.offer_wage_max"
            label="Hasta *"
            type="number"
            :error-messages="wageErrors"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="offer.title"
            label="Título de la oferta *"
            :error-messages="titleErrors"
            :counter="maxCharacters"
            :maxlength="maxCharacters"
            @blur="titleTouched = offer.title !== ''"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="offer.summary"
            label="Resumen corto de la empresa *"
            :error-messages="summaryErrors"
            counter="250"
          />
        </v-flex>

        <v-flex xs12>
          <v-textarea
            v-model="offer.objectives"
            label="Objetivos"
            outline
            counter
          />
        </v-flex>

        <v-flex xs12>
          <v-textarea
            v-model="offer.description"
            label="Funciones"
            outline
            counter
            :error-messages="descriptionErrors"
          />
        </v-flex>

        <v-flex xs12>
          <v-textarea
            v-model="offer.requirements"
            label="Requisitos"
            outline
            counter
            :error-messages="requisiteErrors"
          />
        </v-flex>
        <v-flex xs12>
          <v-switch
            v-if="false"
            v-model="offer.consider_sending_tests"
            color="orange darken-3"
            label="Enviar tests online (si se cumplen las condiciones)"
          />
        </v-flex>
        <v-flex xs12>
          <v-switch
            v-model="offer.laborumPost"
            label="Postear en Laborum"
            :disabled="!IsQualifiedCountry"
          />
        </v-flex>
        <v-flex xs12>
          <v-switch
            v-model="offer.bnePost"
            label="Publicar también en Bolsa Nacional de Empleo (BNE)"
            :disabled="disableBneMultipost"
          />
          <v-alert
            v-for="(err, index) in bneMultipostErrors"
            :key="`bne-error-${index}`"
            color="success"
            :value="true"
            outline
            icon="priority_high"
          >
            {{ err }}
          </v-alert>
        </v-flex>
        <v-flex
          xs12
          mb-4
        >
          <div class="primary--text headline margin_l5">
            <h3>SET DE PREGUNTAS AL CANDIDATO</h3>
            <p
              class="blue-grey--text text--darken-1 mt-3"
              style="font-size: 17px;"
            >
              Es muy importante incluir preguntas de filtro en la oferta de empleo,
              serán de gran ayuda para verificar que
              <strong>los candidatos cumplan con los <template v-if="$vuetify.breakpoint.mdAndUp">
                <br>
              </template></strong>
              <strong> requisitos excluyentes</strong> requeridos para el cargo.
            </p>
            <p
              class="blue-grey--text text--darken-1 mt-3"
              style="white-space: pre-wrap; font-size: 17px;"
            >
              Las respuestas entregadas,
              <strong>te entregaran un % de ajuste al cargo publicado.</strong>
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <br>
              </template>
              Podrás realizar los siguientes tipos de preguntas:
            </p>
            <ul
              class="blue-grey--text text--darken-1 mt-3"
              style="white-space: pre-wrap; font-size: 17px;"
            >
              <li>
                <strong>Preguntas de texto o abiertas.</strong>
              </li>
              <li>
                <strong>Preguntas dicotómicas.</strong>
              </li>
              <li>
                <strong>Preguntas en escala de Likert.</strong>
              </li>
              <li>
                <strong>Preguntas de selección múltiple.</strong>
              </li>
            </ul>
          </div>
        </v-flex>
        <v-flex
          ref="text_id"
          xs12
          mb-3
        >
          <div class="primary--text headline">
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <p class="ml-2">
                <v-icon
                  large
                  color="primary"
                >
                  fal fa-comment-alt-lines
                </v-icon>
                Preguntas de texto o abiertas
              </p>
            </template>
            <template v-else>
              <v-icon
                large
                color="primary"
              >
                fal fa-comment-alt-lines
              </v-icon>
              <p class="ml-1">
                Preguntas de texto o abiertas
              </p>
            </template>
            <div class="margin_l5">
              <p
                class="blue-grey--text text--darken-1 mt-3 mb-4"
                style="white-space: pre-wrap; font-size: 17px;"
              >
                Las preguntas de texto o abiertas, se utilizan para que
                puedas <strong>conocer cómo se expresan los postulantes.</strong><br>
                (sólo puedes realizar 1 de estas preguntas, la respuesta
                <strong>no está considerada en el % de ajuste del postulante)</strong>
              </p>
              <p
                class="blue-grey--text text--darken-1"
                style="white-space: pre-wrap; font-size: 14px;"
              >
                La pregunta Nº1 va por defecto y te permitirá conocer
                las pretensiones de renta de los postulantes.
              </p>
              <v-text-field
                value="Indique su pretensión de renta"
                prefix="1.-"
                append-outer-icon="fas fa-times-circle"
                outline
                disabled
              />
              <v-flex
                v-for="(nq, index) in text_questions_array"
                :key="`text_questions_array-${index}`"
                xs12
              >
                <template
                  v-if="nq.type === 'text'"
                >
                  <v-text-field
                    v-model="nq.text"
                    :prefix="`${index + 2}.-`"
                    append-outer-icon="fas fa-times-circle"
                    background-color="grey"
                    color="red"
                    outline
                    :error-messages="questionErrors[index]"
                    @click:append-outer="handleQuestionClear(text_questions_array, index)"
                  />
                </template>
              </v-flex>
            </div>
          </div>
          <hr class="mt-3">
        </v-flex>
        <v-flex
          v-if="yesno_question.length > 0"
          ref="yesno_id"
          xs12
        >
          <div class="primary--text headline">
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <p class="ml-2">
                <v-icon
                  color="primary"
                  large
                >
                  fal fa-clipboard-list
                </v-icon>
                Preguntas con respuesta SÍ o NO
              </p>
            </template>
            <template v-else>
              <v-icon
                color="primary"
                large
                class="ml-2"
              >
                fal fa-clipboard-list
              </v-icon>
              <p
                class="ml-1"
                style="white-space: pre-wrap; font-size: 22px;"
              >
                Preguntas con respuesta SÍ o NO
              </p>
            </template>
            <div class="margin_l5">
              <p
                class="blue-grey--text text--darken-1 mt-3 mb-4"
                style="white-space: pre-wrap; font-size: 17px;"
              >
                Te recomendamos realizar una pregunta de filtro que sea excluyente
                para el cargo,
                <strong>ya que si el postulante responde NO, obtendrá un ajuste de 0% </strong>
                <template v-if="$vuetify.breakpoint.mdAndUp">
                  <br>
                </template>
                al que no calzar con un requisito importante para ocupar el puesto de trabajo.
              </p>
              <v-flex
                v-for="(nq, index) in yesno_question"
                :key="`yesno_question-${index}`"
                xs12
              >
                <v-text-field
                  v-model="nq.text"
                  :prefix="`${index + 1}.-`"
                  append-outer-icon="fas fa-times-circle"
                  background-color="grey"
                  color="red"
                  outline
                  @click:append-outer="handleQuestionClear(yesno_question, index)"
                />
              </v-flex>
            </div>
          </div>
          <hr class="mt-3 mb-3">
        </v-flex>
        <v-flex
          v-if="likert_question.length > 0"
          ref="likert_id"
          xs12
        >
          <div class="primary--text headline">
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <p class="ml-2">
                <v-icon
                  large
                  color="primary"
                >
                  fal fa-sort-amount-down
                </v-icon>
                Preguntas en escala de Likert
              </p>
            </template>
            <template v-else>
              <v-icon
                color="primary"
                large
              >
                fal fa-sort-amount-down
              </v-icon>
              <p
                class="ml-1"
              >
                Preguntas en escala de Likert
              </p>
            </template>
            <div class="margin_l5">
              <p
                class="blue-grey--text text--darken-1 mt-3 mb-4"
                style="font-size: 17px;"
              >
                Te permiten conocer el nivel de conocimiento de los postulantes.
              </p>
              <v-flex
                v-for="(nq, index) in likert_question"
                :key="`likert_question-${index}`"
                xs12
              >
                <v-text-field
                  v-model="nq.text"
                  :prefix="`${index + 1}.-`"
                  append-outer-icon="fas fa-times-circle"
                  background-color="grey"
                  color="red"
                  outline
                  @click:append-outer="handleQuestionClear(likert_question, index)"
                />
                <v-layout wrap>
                  <v-flex
                    xs12
                    sm6
                  >
                    <v-slider
                      v-if="nq.subtype && nq.subtype === 'likert'"
                      v-model="nq.value_likert_index"
                      :tick-labels="nq.options.map(data => data.text)"
                      :max="nq.options.length - 1"
                      step="1"
                      ticks="always"
                      tick-size="2"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm5
                    mb-4
                  >
                    <v-layout
                      align-center
                      justify-center
                    >
                      <v-flex
                        mt-3
                      >
                        <p
                          class="blue-grey--text text--darken-4 margin_l5"
                          style="white-space: pre-wrap; font-size: 13px;"
                        >
                          Debes mover la barra al nivel de respuesta
                          que quieres recibir, los postulantes
                          sólo verán la pregunta.
                        </p>
                      </v-flex>
                      <v-flex>
                        <v-icon
                          medium
                        >
                          fal fa-exclamation-circle
                        </v-icon>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </div>
          </div>
          <hr class="mt-3 mb-3">
        </v-flex>
        <v-flex
          v-if="multiple_question.length > 0"
          ref="multiple_id"
          xs12
        >
          <div class="primary--text headline">
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <p class="ml-2">
                <v-icon
                  color="primary"
                  large
                >
                  fal fa-ballot-check
                </v-icon>
                Preguntas de selección múltiple
              </p>
            </template>
            <template v-else>
              <v-icon
                color="primary"
                large
                class="ml-2"
              >
                fal fa-ballot-check
              </v-icon>
              <p
                class="ml-1"
                style="white-space: pre-wrap; font-size: 23px;"
              >
                Preguntas de selección múltiple
              </p>
            </template>
            <div class="margin_l5">
              <p
                class="blue-grey--text text--darken-1 mt-3 mb-4"
                style="white-space: pre-wrap; font-size: 17px;"
              >
                Sirven para obtener una respuesta más real del postulante. <br>
                <strong> Debes agregar alternativas y seleccionar
                  las respuestas que deseas recibir</strong>,
                puedes seleccionar más de una altrernativa. <br>
                El candidato tendrá la opción de marcar más de una alternativa.
              </p>
              <v-flex
                v-for="(nq, index) in multiple_question"
                :key="`multiple_question-${index}`"
                xs12
              >
                <v-text-field
                  v-model="nq.text"
                  :prefix="`${index + 1}.-`"
                  append-outer-icon="fas fa-times-circle"
                  background-color="grey"
                  color="red"
                  outline
                  @click:append-outer="handleQuestionClear(multiple_question, index)"
                />
                <v-layout>
                  <p
                    class="blue-grey--text text--darken-1"
                    style="font-size: 14px;"
                  >
                    Debes marcar al menos una opción de respuesta que quieres recibir,
                    los postulantes sólo verán la pregunta
                  </p>
                  <v-icon
                    medium
                    class="mb-3 ml-2"
                  >
                    fal fa-exclamation-circle
                  </v-icon>
                </v-layout>
                <v-flex
                  v-for="(row, idoptions) in nq.multi_options"
                  :key="`multiple_question_${index}_multi_options_${idoptions}`"
                  sm6
                >
                  <v-layout>
                    <v-checkbox
                      v-model="row.expected_option"
                      hide-details
                      class="shrink mr-2"
                    />
                    <v-text-field
                      v-model="row.text"
                      append-outer-icon="fal fa-minus-circle"
                      @click:append-outer="removeMultiChoiceOption(index, idoptions)"
                    />
                  </v-layout>
                </v-flex>
                <v-btn
                  :large="$vuetify.breakpoint.mdAndUp"
                  outline
                  color="primary"
                  class="mb-5"
                  @click="addMultiChoiceOption(index)"
                >
                  <v-text>Agregar nueva alternativa</v-text>
                  <v-icon
                    large
                    right
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-flex>
            </div>
          </div>
          <hr class="mt-3 mb-3">
        </v-flex>
        <v-flex
          v-if="showSalaryQuestionReminder"
          xs12
        >
          <v-alert
            :value="true"
            type="error"
          >
            ¡Recuerda que la primera pregunta ya es sobre la pretensión de renta!
          </v-alert>
        </v-flex>
        <v-layout
          row
          align-center
          justify-center
        >
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip
                :disabled="$vuetify.breakpoint.smAndDown"
                right
                color="white"
              >
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    color="accent"
                    :large="$vuetify.breakpoint.smAndUp"
                    dark
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                  >
                    Agregar nuevo tipo de pregunta
                    <v-icon
                      large
                      right
                      dark
                    >
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  <p class="black--text">
                    Presiona acá para seleccionar el tipo de <br>
                    preguntas que realizarás a tus candidatos
                  </p>
                </span>
              </v-tooltip>
            </template>
            <v-list>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      block
                      color="white"
                      :disabled="text_questions_array.length > 0"
                      @click="addTextQuestion"
                    >
                      <v-layout
                        justify-space-between
                      >
                        pregunta de texto
                        <v-icon
                          right
                          medium
                        >
                          mdi-chevron-right
                        </v-icon>
                      </v-layout>
                    </v-btn>
                  </div>
                </template>
                <span>Máximo 1 pregunta de texto</span>
              </v-tooltip>
              <v-btn
                block
                color="white"
                @click="addYesNoQuestion"
              >
                <v-layout
                  justify-space-between
                >
                  pregunta Si/No
                  <v-icon
                    right
                    medium
                  >
                    mdi-chevron-right
                  </v-icon>
                </v-layout>
              </v-btn>
              <v-btn
                block
                color="white"
                @click="addLikertQuestion"
              >
                <v-layout
                  justify-space-between
                >
                  pregunta Likert
                  <v-icon
                    right
                    medium
                  >
                    mdi-chevron-right
                  </v-icon>
                </v-layout>
              </v-btn>
              <v-btn
                block
                color="white"
                @click="addMultiChoiceQuestion"
              >
                <v-layout
                  justify-space-between
                >
                  Pregunta de selección múltiple
                  <v-icon
                    right
                    medium
                  >
                    mdi-chevron-right
                  </v-icon>
                </v-layout>
              </v-btn>
            </v-list>
          </v-menu>
        </v-layout>
        <v-flex xs12>
          <v-btn
            :disabled="submitting || !offer.position_tier || missingQuestions"
            color="accent"
            @click="submit"
          >
            <v-icon left>
              save
            </v-icon>
            Guardar
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import CountryPicker from '@/components/inputs/CountryPicker.vue';
import RegionPicker from '@/components/inputs/RegionPicker.vue';
import AreaPicker from '@/components/inputs/AreaPicker.vue';
import CityPicker from '@/components/inputs/CityPicker.vue';
import StudiesPicker from '@/components/inputs/StudiesPicker.vue';
import PerkPicker from '@/components/inputs/PerkPicker.vue';

export default {
  name: 'NewOffer',
  components: {
    CountryPicker,
    AreaPicker,
    RegionPicker,
    CityPicker,
    StudiesPicker,
    PerkPicker,
  },
  mixins: [
    JWTConfigMixin,
    validationMixin,
  ],
  data() {
    return {
      submitting: false,
      titleTouched: false,
      offer: {
        consider_sending_tests: false,
        laborumPost: false,
        bnePost: false,
        title: '',
        company: '',
        position: '',
        description: '',
        country: '',
        region: '',
        city: '',
        area: '',
        contract_type: 0,
        position_tier: 0,
        requirements: '',
        vacancies: 1,
        summary: '',
        objectives: '',
        questions: [],
        studies: 0,
        offer_wage_min: 0,
        offer_wage_max: 0,
        perks: [],
      },
      new_questions: [],
      text_questions_array: [],
      yesno_question: [],
      likert_question: [],
      multiple_question: [],
      respErrors: null,
      contracts: [],
      position_tiers: [],
      maxCharacters: 40,
      options: [
        {
          position: 0,
          text: '1',
          value: 1,
        },
        {
          position: 1,
          text: '2',
          value: 2,
        },
        {
          position: 2,
          text: '3',
          value: 3,
        },
        {
          position: 3,
          text: '4',
          value: 4,
        },
        {
          position: 4,
          text: '5',
          value: 5,
        },
      ],
    };
  },
  validations: {
    offer: {
      studies: { required },
    },
  },
  computed: {
    showSalaryQuestionReminder() {
      return this.new_questions.map(data => data.text).join(' ').toLowerCase().includes('renta');
    },
    bneMultipostErrors() {
      let errors = [];
      if (this.isNotChileanOffer) {
        errors = [...errors, 'Sólo disponible para Chile'];
      }
      if (this.offer.description.length > 1000) {
        errors = [
          ...errors,
          'Para publicar en BNE, la descripción debe tener máximo 1000 caracteres.',
        ];
      }
      if (this.offer.title.length > 50) {
        errors = [
          ...errors,
          'Para publicar en BNE, el titulo debe tener máximo 50 caracteres.',
        ];
      }
      return errors;
    },
    disableBneMultipost() {
      return this.bneMultipostErrors.length > 0;
    },
    IsQualifiedCountry() {
      return this.offer.country === 'PE' || this.offer.country === 'CL';
    },
    isNotChileanOffer() {
      return this.offer.country !== 'CL';
    },
    titleErrors() {
      return (this.respErrors === null ? [] : this.respErrors.title);
    },
    companyErrors() {
      return (this.respErrors === null ? [] : this.respErrors.company);
    },
    positionErrors() {
      return (this.respErrors === null ? [] : this.respErrors.position);
    },
    summaryErrors() {
      return (this.respErrors === null ? [] : this.respErrors.summary);
    },
    studiesErrors() {
      return (this.respErrors === null ? [] : this.respErrors.studies);
    },
    areaErrors() {
      return (this.respErrors === null ? [] : this.respErrors.area);
    },
    countryErrors() {
      return (this.respErrors === null ? [] : this.respErrors.country);
    },
    regionErrors() {
      return (this.respErrors === null ? [] : this.respErrors.region);
    },
    cityErrors() {
      return (this.respErrors === null ? [] : this.respErrors.city);
    },
    vacanciesErrors() {
      return (this.respErrors === null ? [] : this.respErrors.vacancies);
    },
    descriptionErrors() {
      return (this.respErrors === null ? [] : this.respErrors.description);
    },
    requisiteErrors() {
      return (this.respErrors === null ? [] : this.respErrors.requirements);
    },
    wageErrors() {
      return (this.respErrors === null ? [] : this.respErrors.wage);
    },
    questionErrors() {
      if (this.respErrors === null || !this.respErrors.questions) {
        return new Array(this.offer.questions.length);
      }
      const errors = [];
      this.respErrors.questions.forEach(el => {
        errors.push(el.question);
      });
      return errors;
    },
    missingQuestions() {
      if (this.multiple_question.length > 0) {
        const options = this.multiple_question.map(data => {
          const copy = { ...data };
          copy.multi_options = copy.multi_options.map(option => {
            const copyTwo = { ...option };
            return copyTwo.expected_option;
          });
          return copy.multi_options.includes(true);
        });
        return options.some(data => data === false);
      }
      return false;
    },
    payload() {
      const res = { ...this.offer };
      // properly map new type of text_questions and range_questions
      res.text_questions = [...this.text_questions_array];
      const yesno = this.yesno_question.map((data, index) => {
        const copy = { ...data };
        copy.position = this.text_questions_array.length + index;
        return copy;
      });
      const likert = this.likert_question.map((data, index) => {
        const copy = { ...data };
        copy.value_likert = this.options[data.value_likert_index].value;
        copy.position = this.text_questions_array.length + this.yesno_question.length + index;
        return copy;
      });
      res.range_questions = [...yesno, ...likert];
      res.multi_choice_questions = this.multiple_question.map((data, idx) => {
        const copy = { ...data };
        copy.position = this.text_questions_array.length
          + this.yesno_question.length + this.likert_question.length + idx;
        copy.multi_options = copy.multi_options.map((option, index) => {
          const copyTwo = { ...option };
          copyTwo.position = index;
          return copyTwo;
        });
        return copy;
      });
      // properly map old-style text questions
      res.questions = res.text_questions.map(data => ({
        position: data.position,
        question: data.text,
      }));
      return res;
    },
  },
  watch: {
    isNotChileanOffer(val) {
      if (val) {
        this.offer.bnePost = false;
      }
    },
    IsQualifiedCountry(val) {
      if (val === false) {
        this.offer.laborumPost = false;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      axios.get(this.$store.state.endpoints.offers.admin.contracts, this.jwtConfig)
        .then(resp => {
          this.contracts = resp.data;
          this.offer.contract_type = this.contracts.filter(el => el.text === 'Full Time')[0].value;
        });
      axios.get(this.$store.state.endpoints.offers.admin.position_tiers, this.jwtConfig)
        .then(resp => {
          this.position_tiers = resp.data;
        });
      const duplicatee = this.$route.params.offer;
      if (duplicatee) {
        this.offer.company = duplicatee.company;
        this.offer.title = duplicatee.title;
        this.offer.position = duplicatee.position;
        this.offer.summary = duplicatee.summary;
        this.offer.objectives = duplicatee.objectives;
        this.offer.description = duplicatee.description;
        this.offer.requirements = duplicatee.requirements;
        this.offer.country = duplicatee.country;
        this.offer.contract_type = duplicatee.contract_type;
        this.offer.region = duplicatee.region;
        this.offer.city = duplicatee.city;
        this.offer.area = duplicatee.area;
        this.offer.vacancies = duplicatee.vacancies;
        this.offer.studies = duplicatee.studies;
        this.offer.consider_sending_tests = duplicatee.consider_sending_tests;
        this.offer.questions = duplicatee.questions.map(q => q.question);
        this.offer.perks = duplicatee.perks.map(q => q.perk);
      }
    });
  },
  methods: {
    handleQuestionClear(data, index) {
      data.splice(index, 1);
    },
    addYesNoQuestion() {
      this.yesno_question.push({
        type: 'range',
        subtype: 'yesno',
        position: 0,
        text: '',
        options: [
          {
            position: 0,
            text: 'Si',
            value: 1,
          },
          {
            position: 1,
            text: 'No',
            value: 0,
          },
        ],
      });
      this.$vuetify.goTo(this.$refs.yesno_id, {
        duration: 1000,
        offset: 0,
        easing: 'easeOutCubic',
      });
    },
    addTextQuestion() {
      this.text_questions_array.push({
        type: 'text',
        position: this.text_questions_array.length,
        text: '',
      });
      this.$vuetify.goTo(this.$refs.text_id, {
        duration: 1000,
        offset: 0,
        easing: 'easeOutCubic',
      });
    },
    addLikertQuestion() {
      this.likert_question.push({
        type: 'range',
        subtype: 'likert',
        position: 0,
        text: '',
        options: this.options,
        value_likert_index: 4,
      });
      this.$vuetify.goTo(this.$refs.likert_id, {
        duration: 1000,
        offset: 0,
        easing: 'easeOutCubic',
      });
    },
    addMultiChoiceQuestion() {
      this.multiple_question.push({
        type: 'multichoice',
        position: 0,
        text: '',
        multi_options: [
          {
            text: '',
            expected_option: false,
          },
        ],
      });
      this.$vuetify.goTo(this.$refs.multiple_id, {
        duration: 1000,
        offset: 0,
        easing: 'easeInOutCubic',
      });
    },
    addMultiChoiceOption(index) {
      this.multiple_question[index].multi_options.push({
        text: '',
        expected_option: false,
      });
    },
    removeMultiChoiceOption(index, idoptions) {
      this.multiple_question[index].multi_options.splice(idoptions, 1);
    },
    shortensSelectedText(text) {
      if (text.length > 40) {
        return `${text.substr(0, 40)}`;
      }
      return text;
    },
    updateTitle() {
      if (!this.titleTouched) {
        if (this.offer.position) {
          this.offer.title = this.shortensSelectedText(this.offer.position);
        }
      }
    },
    submit() {
      this.submitting = true;
      this.respErrors = null;
      axios.post(this.$store.state.endpoints.offers.admin.create, this.payload, this.jwtConfig)
        .then(resp => {
          this.submitting = false;
          this.$router.push({ name: 'admin:offers' });
          this.$store.commit('adminSnackbarUp', resp.data.response);
        })
        .catch(e => {
          this.respErrors = e.response.data;
          this.$vuetify.goTo(0);
          this.$store.commit('adminSnackbarUp',
            'Verifique que los datos del formulario sean correctos.');
          this.$store.commit('unsetLoading');
          this.submitting = false;
        });
    },
  },
};
</script>

<style scoped>
@media (min-width: 600px) {
  .margin_l5 {
    margin-left: 50px;
  }
}

@media (max-width: 960px) {
  .margin_l5 {
    margin-left: 3px;
  }
}
</style>
